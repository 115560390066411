<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    modelValue: String,
    color: String,
    error: Boolean,
});

defineEmits(['update:modelValue']);

const input = ref(null);
const classes = () => {
    let output = ['input input-bordered w-full text-sm'];

    if (props.error) {
        output.push('placeholder:text-error text-error')
    }

    if (props.color) {
        output.push(`text-${props.color}`)
    }

    return output.join(' ')
}

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        :class="classes()"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>
